import {AnyAction} from 'redux';
import {CommonReducer as State} from '../../@types/reducers';

const initialState: State = {
    client: null
};

const commonReducer = (state = initialState, action: AnyAction): State => {
    switch (action.type) {
        default:
            return state;
    }
};

export default commonReducer;
