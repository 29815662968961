import React from "react";


const LeftMenu: React.FC = () => {
    return <footer className="l-f cf">
        <div className="sw cf">
            <div className="sw-c cf">
                <section data-wn-border-element="s-f-border" className="s s-f s-f-basic s-f-simple wnd-w-wider wnd-s-higher sc-m">
                    <div className="s-w">
                        <div className="s-o">
                            <div className="s-bg">
                                <div className="s-bg-l"/>
                            </div>
                            <div className="s-c s-f-l-w s-f-border">
                                <div className="s-f-l b-s b-s-t0 b-s-b0">
                                    <div className="s-f-l-c s-f-l-c-first">
                                        <div className="s-f-cr">
                                            <span className="it b link">
                                            	<span className="it-c">📍Lecce 📍Patù<br/>📍&nbsp; Consulenza online&nbsp;</span>
                                            </span>
                                        </div>
                                        <div className="s-f-sf">
                                            <span className="sf b">
                                                <span className="sf-content sf-c link">Powered by <a
                                                    href="https://www.deplot.xyz"
                                                    rel="noopener nofollow" target="_blank">deplot.xyz</a>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="s-f-l-c s-f-l-c-last">
                                        <div className="s-f-lang lang-select cf"/>
                                    </div>
                                    <div className="s-f-l-c s-f-l-c-currency">
                                        <div className="s-f-ccy ccy-select cf"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </footer>
};

export default LeftMenu;
