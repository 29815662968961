import React, {useState} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import jsPDF from 'jspdf';
import {style} from "./style";

const Privacy: React.FC = () => {

    const padRef = React.useRef<any>(null);
    const reportTemplateRef = React.useRef<any>(null);

    const [trimmedDataURL, setTrimmedDataURL] = useState<any>()

    return <div>
        <div ref={reportTemplateRef}>
            <div style={style.page}>
                <h1 style={{textAlign: 'center'}}>INFORMATIVA E CONSENSO SUL TRATTAMENTO DEI DATI PERSONALI</h1>

                <p>ai sensi degli artt. 13 e 14 GDPR - Regolamento UE 2016/679</p>

                <p><br/></p>

                <p>Gentile Signore/a, <br/></p>
                <p style={{textAlign: 'justify'}}>
                    il suddetto regolamento prevede una serie di obblighi in capo di chi effettua “trattamenti” (cioè
                    raccolta, registrazione, elaborazione, conservazione, comunicazione, diffusione, ecc.) di dati
                    personali riferiti ad altri soggetti.
                    <br/>
                    A tale proposito ai sensi degli artt. 13 e 14 GDPR - Regolamento UE 2016/679 (di seguito GDPR) ed in
                    relazione ai dati personali di cui il sottoscritto Dott. Andrea Lia entrerà in possesso, La informo
                    di quanto segue:
                    <br/>
                </p>
                <h2>1. Finalità del trattamento dei dati</h2>
                <p style={{textAlign: 'justify'}}>La raccolta ed il successivo trattamento dei suoi dati personali ha lo
                    scopo
                    di consentire al Dott.
                    Andrea Lia (titolare del trattamento) una corretta valutazione del suo stato nutrizionale e la
                    conseguente preparazione di un piano nutrizionale personalizzato, nell'ambito di un rapporto
                    fiduciario ed unicamente per la corretta e completa esecuzione dell'incarico professionale ricevuto
                    ed opportunamente siglato in “Lettera d'incarico e dichiarazione di consenso al trattamento dei dati
                    personali” e per svolgere obblighi legali e fiscali (es. fatturazione, dichiarazione dei
                    redditi).
                    <br/>
                </p>
                <p style={{textAlign: 'justify'}}>Il trattamento dei suoi dati personali rientranti nel novero dei dati
                    sensibili quali dati idonei a
                    rivelare il suo stato di salute può essere autorizzato solo con il suo consenso manifestato per
                    iscritto allegato alla presente informativa.</p>
                <p><br/></p>
                <h2>2. Modalità del trattamento dei dati</h2>
                <p style={{textAlign: 'justify'}}>Il trattamento è realizzato per mezzo delle seguenti operazioni o
                    complesso
                    di operazioni: raccolta,
                    registrazione, organizzazione, conservazione, consultazione, elaborazione, selezione, estrazione,
                    raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione dei
                    dati.</p>
                <p>I dati sono forniti da voi stessi.</p>
                <p style={{textAlign: 'justify'}}>Il trattamento è effettuato anche, e soprattutto, con l'ausilio di
                    strumenti
                    elettronici, ma
                    adottando misure di protezione idonee a garantire la sicurezza e la riservatezza dei dati personali,
                    nel rispetto del segreto professionale. Tutte le banche dati sono protette da specifiche password di
                    accesso esclusivamente a conoscenza del Dott. Andrea Lia e che vengono cambiate ogni 30 giorni.</p>
                <p style={{textAlign: 'justify'}}>Le lettere d'incarico e i consensi sono conservati in un apposito
                    faldone riposto in un armadio chiuso a chiave presso la sede fiscale del Titolare del
                    trattamento.</p>
                <p><br/></p>
                <h2>3. Base giuridica del trattamento</h2>
                <p style={{textAlign: 'justify'}}>Il conferimento di dati personali comuni, sensibili, ed il loro
                    trattamento,
                    è strettamente
                    necessario ai fini dello svolgimento delle attività di cui al punto 1 e per adempiere a quanto
                    indicato nella lettera d'incarico da lei firmata ed allegata alla presente informativa.</p>
                <p><br/></p>
                <h2>4. Rifiuto di conferimento dei dati</h2>
                <p style={{textAlign: 'justify'}}>L'eventuale suo rifiuto di conferire i dati personali nel caso di cui
                    al
                    punto 3, comporta
                    l'impossibilità di adempiere alle attività di cui al punto 1.
                    <br/>
                </p>
                <h2>5. Periodo di conservazione e cancellazione dei dati</h2>
                <p style={{textAlign: 'justify'}}>I dati personali comuni e sensibili verranno conservati, con le
                    modalità indicate al punto 2, per
                    tutto il periodo della durata del rapporto di consulenza tra l'interessato e il titolare. In
                    particolare, il rapporto si intende concluso alla sospensione dei controlli. Passato il termine di
                    tre anni dall'ultima consulenza eseguita, i dati verranno cancellati.</p>
                <p><br/></p>
                <h2>6. Comunicazione dei dati</h2>
                <p style={{textAlign: 'justify'}}>I dati personali possono essere comunicati, per le finalità di cui al
                    punto 1,
                    a collaboratori
                    esterni:</p>
                <ul style={{textAlign: 'justify'}}>
                    <li>
                        <p>Dottore commercialista per la sola ed unica parte relativa agli oneri fiscali;</p>
                    </li>
                    <li>
                        <p>Collaboratori esterni (psicologo, medico specialista, medico curante) che possano essere di
                            aiuto nell'adempiere le attività di cui al punto 1 – tali dati saranno conferiti solo dopo
                            suo consenso anche solo
                            orale.</p>
                    </li>
                </ul>
                <p><br/></p>

                <h2>7. Diffusione dei dati e trasferimento dati all'estero</h2>
                <p style={{textAlign: 'justify'}}>I dati personali non sono soggetti a diffusione e trasferimento
                    all'esterno se
                    non per l'esecuzione
                    dei fini per i quali viene conferito mandato al professionista. I dati personali non sono trasferiti
                    verso paesi dell'Unione Europea e verso Paesi terzi rispetto all'Unione Europea nell'ambito delle
                    finalità di cui al punto 1.</p>
                <p><br/></p>
                <h2>8. Diritti dell'interessato.</h2>
                <p style={{textAlign: 'justify'}}>
                    Quale interessato al trattamento lei gode dei seguenti diritti che
                    potrà esercitare in qualsiasi momento:
                </p>
                <ul id="l3" style={{textAlign: 'justify'}}>
                    <li>
                        <p>Accesso ai suoi dati personali: ha diritto di avere conoscenza dell'origine dei dati, della
                            finalità e delle modalità del trattamento;</p></li>
                    <li>
                        <p>Rettifica o integrazione dei dati o cancellazione o limitazione del trattamento;</p>
                    </li>
                    <li>
                        <p>Opposizione al trattamento;</p>
                    </li>
                    <li>
                        <p>Portabilità dei dati;</p>
                    </li>
                    <li>
                        <p>Revoca del consenso - con conseguente impossibilità del titolare di adempiere a quanto
                            indicato al punto 1 come previsto dal punto 4;</p>
                    </li>
                    <li>
                        <p>Proporre reclamo all'autorità di controllo (garante della privacy).</p>
                    </li>
                </ul>
                <p><br/></p>
                <h2>9. Titolare del trattamento.</h2>
                <p style={{textAlign: 'justify'}}>Titolare e Responsabile del trattamento è la Dott. Andrea Lia, Sede
                    legale Via
                    A. Viti, 23 – 73040
                    Morciano di Leuca (LE) numero di telefono: 3513037037, e-mail: andrea.lia.lecce@gmail.com</p>
                <p><br/></p>

                <h2 style={{paddingTop: '2pt',}}>10. Finalità Marketing</h2>

                <p style={{textAlign: 'justify'}}>Per finalità di marketing e altre comunicazioni di natura commerciale
                    (che
                    possano essere di
                    interesse per l'utente in quanto relazionate ai Servizi ricevuti): solo con il consenso espresso e
                    specifico dell'utente, revocabile in qualsiasi momento.
                    <br/>
                    La informo inoltre che, come previsto dalla normativa vigente, i suoi dati saranno comunicati al
                    Sistema Tessera Sanitaria, che li tratterà comunque in forma aggregata, per l'elaborazione della Sua
                    dichiarazione dei redditi precompilata.
                    <br/>
                </p>

                <p>
                    <input type='checkbox'/>
                    IL PAZIENTE SI OPPONE ALLA TRASMISSIONE AL SISTEMA TS AI SENSI DELL.ART. 3 DEL DM 31 LUGLIO 2015 ed
                    i dati non saranno comunicati al Sistema tessera Sanitario.
                </p>

                <p style={{marginTop: '10pt'}}>FIRMA</p>

                <div className='sig-container'>
                    {trimmedDataURL
                        ? <img
                            alt='signature'
                            src={trimmedDataURL}/>
                        : <>
                            <div>
                                <SignatureCanvas
                                    ref={padRef}
                                    canvasProps={{width: 400, height: 100, className: 'sig-canvas'}}
                                />
                            </div>
                            <div>
                                <button type='button' onClick={() => padRef.current.clear()}>
                                    Svuota
                                </button>
                                <button
                                    type='button'
                                    onClick={() => setTrimmedDataURL(padRef.current.getTrimmedCanvas().toDataURL('image/png'))}
                                >
                                    Salva
                                </button>
                            </div>
                        </>}
                </div>
            </div>
        </div>

        {
            trimmedDataURL &&
            <button className="button" onClick={() => {
                const doc = new jsPDF({
                    format: 'a4',
                    unit: 'pt',
                });

                // Adding the fonts.
                //doc.setFont('Inter-Regular', 'normal');

                doc.html(reportTemplateRef.current, {
                    async callback(doc) {
                        await doc.save('Privacy da firmare');
                    },
                });
            }}>
                Generate PDF
            </button>
        }

    </div>

};

export default Privacy;
