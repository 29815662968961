import {combineReducers} from 'redux';
import {ReducerMap} from './storeTypes';
import commonReducer from "./reducers/commonReducer";

export function createReducer(asyncReducers: ReducerMap) {
    return combineReducers({
        common: commonReducer,
        ...asyncReducers
    });
}

