import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import LeftMenu from './LeftMenu';
import Home from "../../pages/public/home";
import AboutMe from "../../pages/public/about-me";
import PriceList from "../../pages/public/pricelist";
import Contact from "../../pages/public/contact";
import Footer from "./Footer";

const PublicContainer: React.FC = () => {
    return <div
        className='l wt-home ac-i ac-n l-sidebar l-d-none b-btn-sq b-btn-s-l b-btn-dso b-btn-bw-1 img-d-n img-t-u img-h-z line-solid b-e-ds lbox-l c-s-n hb-on wnd-free-bar-fixed wnd-fe desktop'>
        <div
            className='wnd-page l-page cs-dim ac-f25151 ac-normal t-t-fs-s t-t-fw-m t-t-sp-n t-t-d-n t-s-fs-s t-s-fw-l t-s-sp-n t-s-d-n t-p-fs-s t-p-fw-m t-p-sp-n t-h-fs-s t-h-fw-l t-h-sp-n t-bq-fs-m t-bq-fw-m t-bq-sp-n t-bq-d-l t-btn-fw-l t-nav-fw-l t-pd-fw-l t-nav-tt-u'
            style={{marginBottom: 0}}
        >
            <div className='l-w t cf t-23'>
                <LeftMenu/>
                <main className='l-m cf'>
                    <div className='sw cf'>
                        <div className='sw-c cf'>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/about-me" element={<AboutMe/>}/>
                                <Route path="/pricelist" element={<PriceList/>}/>
                                <Route path="/contact" element={<Contact/>}/>
                                <Route path="*" element={<Navigate to="/"/>}/>
                            </Routes>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
    </div>
};

export default PublicContainer;
