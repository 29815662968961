import React from 'react';
import '../../App.css';

const PriceList: React.FC = () => {
    return <section className="s s-basic cf sc-ml wnd-background-pattern wnd-w-wider wnd-s-normal wnd-h-auto">
        <div className="s-w cf">
            <div className="s-o s-fs cf">
                <div className="s-bg cf">
                    <div className="s-bg-l wnd-background-pattern  bgpos-center-center bgatt-scroll"
                         style={{backgroundImage: "url('https://d1di2lzuh97fh2.cloudfront.net/files/1k/1kv/1kvudr.jpg?ph=ce5d960f58')"}}
                    />
                </div>
                <div className="s-c s-fs cf">
                    <div className="ez cf wnd-no-cols">
                        <div className="ez-c">
                            <div className="b b-text cf">
                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><h1 className="wnd-align-center"><strong>Tariffe</strong></h1>
                                </div>
                            </div>
                            <div className="mt mt-pricelist b-s cf">
                                <div className="mt-c cf">
                                    <div className="mt-i cf">
                                        <div className="mt-i-c cf mt-border line-color">
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><p>Prima anamnesi:</p><p>Lettura parametri ematici, acquisizione
                                                    informazioni stile di vita e comportamenti alimentari.</p><p><br/></p><p>Visita:&nbsp;</p><p>Peso, altezza, circonferenze e
                                                    analisi bioimpedenziometria per la valutazione della composizione corporea.</p><p><br/>Dieta:&nbsp;</p><p>Strategia alimentare
                                                    ritagliata sulle esigenze e obiettivi del paziente.</p><p><br/></p>
                                                </div>
                                            </div>
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><p>€80</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-i cf">
                                        <div className="mt-i-c cf mt-border line-color">
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><p><br/></p><p>Visita di controllo mensile:</p><p>Peso, altezza,
                                                    circonferenze e analisi bioimpedenziometria per la valutazione della composizione corporea.
                                                </p><p><br/></p><p>Dieta:</p><p>Ricalibrazione eventuale del piano alimentare stabilito durante la prima visita.</p><p><br/></p>
                                                </div>
                                            </div>
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><p>€40</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-i cf">
                                        <div className="mt-i-c cf mt-border line-color">
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><p>Consulenza online:</p><p>Lettura parametri ematici, acquisizione
                                                    informazioni stile di vita e comportamenti alimentari.</p><p>&nbsp;<br/></p><p>Dieta:</p><p>Strategia alimentare ritagliata
                                                    sulle
                                                    esigenze e obiettivi del paziente.&nbsp;<br/></p>
                                                </div>
                                            </div>
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><p>€60</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default PriceList;
