import React, {Fragment} from 'react';
import Privacy from './pages/forms/privacy';
import Assignement from "./pages/forms/assignment";
import PublicContainer from "./components/layout/PublicContainer";
import {BrowserRouter, Route, Routes} from "react-router-dom";

const App: React.FC = () => {

    return <Fragment>
        <BrowserRouter>
            <Routes>
                <Route path="/private/privacy" element={<Privacy/>}/>
                <Route path="/private/incarico" element={<Assignement/>}/>
                <Route path="*" element={<PublicContainer/>}/>
            </Routes>
        </BrowserRouter>
    </Fragment>
}

export default App