import React from 'react';
import '../../App.css';

const AboutMe: React.FC = () => {
    return <section className="s s-basic cf sc-ml wnd-background-pattern wnd-w-wider wnd-s-normal wnd-h-auto">
        <div className="s-w cf">
            <div className="s-o s-fs cf">
                <div className="s-bg cf">
                    <div className="s-bg-l wnd-background-pattern  bgpos-center-center bgatt-scroll"
                         style={{backgroundImage: "url('https://d1di2lzuh97fh2.cloudfront.net/files/1k/1kv/1kvudr.jpg?ph=ce5d960f58')"}}
                    />
                </div>
                <div className="s-c s-fs cf">
                    <div className="ez cf">
                        <div className="ez-c">
                            <div className="cw cf">
                                <div className="cw-c cf">
                                    <div className="c cf" style={{width: "50%"}}>
                                        <div className="c-c cf">
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf">
                                                    <h2>
                                                        <strong>
                                                            <span className="wnd-font-size-130">Chi sono</span>
                                                        </strong>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf">

                                                    <h1>Biologo Nutrizionista, Dott. Andrea Lia&nbsp;<br/></h1><p><br/>Sono laureato in Biologia (curriculum Agroalimentare) e ho
                                                    conseguito un dottorato di ricerca (PhD) in Scienze delle Produzioni Animali e Vegetali.</p><p><br/>Ho studiato presso
                                                    l'Università del Salento, Università di Oxford e Università di Leicester (UK).</p><p>Sono anche un ricercatore dell'Istituto
                                                    Scienze Produzioni Alimentari del Consiglio Nazionale delle Ricerche (ISPA-CNR) e, per un breve periodo, ho lavorato per il
                                                    Telethon Institute of Genetic and Medicine.</p><p><br/></p><p>Mi occupo di nutrizione e ricomposizione corporea.</p><p><br/></p>
                                                    <p><strong style={{fontSize: "0.98125rem", letterSpacing: "0px"}}>Ricevo a:</strong></p><p><strong
                                                    style={{fontSize: "0.98125rem", letterSpacing: "0px"}}>- Patù, in via G. Garibaldi 7 (provincia di Lecce);</strong></p><p>
                                                    <strong
                                                        style={{fontSize: "0.98125rem", letterSpacing: "0px"}}>- Le</strong><strong
                                                    style={{fontSize: "0.98125rem", letterSpacing: "0px"}}>cce,
                                                    in via Raffaello Sanzio 4;<br/><br/></strong></p><p><strong style={{fontSize: "0.98125rem", letterSpacing: "0px"}}>-
                                                    Nutrizionista,
                                                    consulenza online.</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c cf" style={{width: "50%"}}>
                                        <div className="c-c cf">
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf">
                                                    <h2>I servizi</h2>
                                                </div>
                                            </div>
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf">
                                                    <ul>
                                                        <li>Consulenza nutrizionale</li>
                                                        <li>Valutazione della composizione corporea</li>
                                                        <li>Valutazione degli stili di vita</li>
                                                        <li>Piani alimentari personalizzati</li>
                                                        <li>Monitoraggio del peso</li>
                                                        <li>Supporto motivazionale</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="c-c cf">
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf">
                                                    <h2>Le nostre collaborazioni</h2>
                                                </div>
                                            </div>
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf">
                                                    <ul>
                                                        <li>Palestra Luxfit - Patù</li>
                                                        <li>Palestra MoaGym - Lecce</li>
                                                        <li>Studio di fisioterapia - Hands on clinic - Lecce</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default AboutMe;
