import React, {useState} from "react";


const LeftMenu: React.FC = () => {
    // const location = useLocation();
    // const navigate = useNavigate();

    const [showMenu, setShowMenu] = useState(false)

    return <header className="l-h cf">
        <div className="sw cf">
            <div className="sw-c cf">
                <section className="s s-hb cf sc-cd  sc-a wnd-w-narrow wnd-hbs-on wnd-hbi-on wnd-hbi-news-on">
                    <div className="s-w cf">
                        <div className="s-o cf">
                            <div className="s-bg cf">
                                <div className="s-bg-l"/>
                            </div>
                            <div className="s-c">
                                <div className="s-hb-c cf">
                                    <div className="hb-si">
                                        <div className="si">
                                            <div className="si-c"><a href="https://www.facebook.com/andrealiabio" target="_blank" rel="noreferrer nofollow" title="Facebook">
                                                <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18px" width="18px">
                                                    <path
                                                        d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                                                        fill="#bebebe"></path>
                                                </svg>
                                            </a><a href="https://www.instagram.com/andrealiabio/?fbclid=IwAR1S9IePFCJ2G5OfIJSX2rSLb5AA-wE7izaqENPEOhsq5320XpcvF7Elmms"
                                                   target="_blank" rel="noreferrer nofollow" title="Instagram">
                                                <svg viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg" height="18px" width="18px">
                                                    <path
                                                        d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 001.384 2.126A5.868 5.868 0 004.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 002.126-1.384 5.86 5.86 0 001.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 00-1.384-2.126A5.847 5.847 0 0019.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 01-.899 1.382 3.744 3.744 0 01-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 01-1.379-.899 3.644 3.644 0 01-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 100 12.324 6.162 6.162 0 100-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 01-2.88 0 1.44 1.44 0 012.88 0z"
                                                        fill="#bebebe"></path>
                                                </svg>
                                            </a><a href="https://wa.me/message/HWFXYEAPHO54A1" target="_blank" rel="noreferrer nofollow" title="Whatsapp">
                                                <svg viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg" height="18px" width="18px">
                                                    <path
                                                        d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51a12.8 12.8 0 00-.57-.01c-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"
                                                        fill="#bebebe"></path>
                                                </svg>
                                            </a></div>
                                        </div>
                                    </div>
                                    <div className="hb-ci">
                                        <div className="ci">
                                            <div className="ci-c">
                                                <div className="ci-news">
                                                    <span>Tel. 3513037037&nbsp;</span>
                                                </div>
                                                <div className="ci-mail">
                                                    <span><a href="mailto:andrea.lia.lecce@gmail.com">andrea.lia.lecce@gmail.com</a></span>
                                                </div>
                                                <div className="ci-phone">
                                                    <span><a href="tel:3513037037">3513037037</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hb-ccy">

                                    </div>
                                    <div className="hb-lang">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section data-space="true"
                         className="s s-hn s-hn-sidebar wnd-mt-sidebar menu-default s-hn-slogan logo-sidebar sc-m wnd-background-pattern hm-claims wnd-nav-sticky wn-space-bottom">
                    <div className="s-w">
                        <div className="s-o">
                            <div className="s-bg">
                                <div className="s-bg-l wnd-background-pattern  bgpos-center-center bgatt-scroll"
                                    //style="background-image:url('https://d1di2lzuh97fh2.cloudfront.net/files/4c/4c5/4c5fs8.jpg?ph=ce5d960f58')"
                                />
                            </div>
                            <div className="h-w h-f wnd-fixed">
                                <div className="s-i">
                                    <div className="n-l">
                                        <div className="s-c">
                                            <div className="logo-block">
                                                <div className="b b-l logo logo-default logo-nb wnd-font-size-20 pt-serif logo-25 b-ls-l wnd-logo-with-text wnd-iar-1-1"
                                                     id="wnd_LogoBlock_847159738" data-wnd_mvc_type="wnd.fe.LogoBlock">
                                                    <div className="b-l-c logo-content">
                                                        <div className="b-l-image logo-image">
                                                            <div className="b-l-image-w logo-image-cell">
                                                                <picture>
                                                                    <source type="image/jpeg"
                                                                            srcSet="https://ce5d960f58.cbaul-cdnwnd.com/0579f052660b6a594743829b4a229daf/200000000-1f87e1f883/450/Foto%20Andrea.jpeg?ph=ce5d960f58 352w, https://ce5d960f58.cbaul-cdnwnd.com/0579f052660b6a594743829b4a229daf/200000000-1f87e1f883/700/Foto%20Andrea.jpeg?ph=ce5d960f58 548w, https://ce5d960f58.cbaul-cdnwnd.com/0579f052660b6a594743829b4a229daf/200000000-1f87e1f883/Foto%20Andrea.jpeg?ph=ce5d960f58 715w"
                                                                            sizes="(min-width: 600px) 450px, (min-width: 360px) calc(100vw * 0.8), 100vw"/>
                                                                    <img
                                                                        src="https://ce5d960f58.cbaul-cdnwnd.com/0579f052660b6a594743829b4a229daf/200000000-1f87e1f883/Foto%20Andrea.jpeg?ph=ce5d960f58"
                                                                        alt="" width="715" height="914" className="wnd-logo-img"
                                                                    />
                                                                </picture>
                                                            </div>
                                                        </div>
                                                        <div className="b-l-br logo-br"></div>
                                                        <div className="b-l-text logo-text-wrapper">
                                                            <div className="b-l-text-w logo-text">
                                                                <span className="b-l-text-c logo-text-cell"><strong>Dott. Andrea Lia</strong></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="logo-slogan claim">
									<span className="sit b claim-default claim-nb open-sans slogan-13">
	<span className="sit-w"><span className="sit-c">Biologo, PhD - Nutrizionista&nbsp;</span></span>
</span>
                                                </div>
                                            </div>
                                            <div className="cart-block">
                                            </div>
                                            <div id="menu-mobile" onClick={() => setShowMenu(!showMenu)}>
                                                <a href="#" id="menu-submit"><span></span>Menu</a>
                                            </div>
                                            <div id="menu-block">
                                                <nav id="menu" data-type="vertical" data-align="centered" className={`loaded ${showMenu && 'visible open'}`}>
                                                    <div className="menu-font menu-wrapper">
                                                        <a href="#"
                                                           className="menu-close"
                                                           rel="nofollow"
                                                           title="Close Menu"
                                                           onClick={() => setShowMenu(false)}
                                                        ></a>
                                                        <ul className="level-1">
                                                            <li className="wnd-active wnd-homepage">
                                                                <a className="menu-item" href="/home/"><span className="menu-item-text">Home</span></a>
                                                            </li>
                                                            <li>
                                                                <a className="menu-item" href="/about-me/"><span className="menu-item-text">Informazioni </span></a>
                                                            </li>
                                                            <li>
                                                                <a className="menu-item" href="/pricelist/"><span className="menu-item-text">Tariffe</span></a>
                                                            </li>
                                                            <li>
                                                                <a className="menu-item" href="/contact/"><span className="menu-item-text">Contatti</span></a>
                                                            </li>
                                                        </ul>
                                                        <span className="more-text">More</span>
                                                        <div className="hb-ci">
                                                            <div className="ci">
                                                                <div className="ci-c">
                                                                    <div className="ci-news">
                                                                        <span>Tel. 3513037037&nbsp;</span>
                                                                    </div>
                                                                    <div className="ci-mail">
                                                                        <span><a href="mailto:andrea.lia.lecce@gmail.com">andrea.lia.lecce@gmail.com</a></span>
                                                                    </div>
                                                                    <div className="ci-phone">
                                                                        <span><a href="tel:3513037037">3513037037</a></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="hb-si">
                                                            <div className="si">
                                                                <div className="si-c"><a href="https://www.facebook.com/andrealiabio" target="_blank" rel="noreferrer nofollow"
                                                                                         title="Facebook">
                                                                    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18px" width="18px">
                                                                        <path
                                                                            d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                                                                            fill="#bebebe"></path>
                                                                    </svg>
                                                                </a><a
                                                                    href="https://www.instagram.com/andrealiabio/?fbclid=IwAR1S9IePFCJ2G5OfIJSX2rSLb5AA-wE7izaqENPEOhsq5320XpcvF7Elmms"
                                                                    target="_blank" rel="noreferrer nofollow" title="Instagram">
                                                                    <svg viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg" height="18px" width="18px">
                                                                        <path
                                                                            d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 001.384 2.126A5.868 5.868 0 004.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 002.126-1.384 5.86 5.86 0 001.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 00-1.384-2.126A5.847 5.847 0 0019.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 01-.899 1.382 3.744 3.744 0 01-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 01-1.379-.899 3.644 3.644 0 01-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 100 12.324 6.162 6.162 0 100-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 01-2.88 0 1.44 1.44 0 012.88 0z"
                                                                            fill="#bebebe"></path>
                                                                    </svg>
                                                                </a><a href="https://wa.me/message/HWFXYEAPHO54A1" target="_blank" rel="noreferrer nofollow" title="Whatsapp">
                                                                    <svg viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg" height="18px" width="18px">
                                                                        <path
                                                                            d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51a12.8 12.8 0 00-.57-.01c-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"
                                                                            fill="#bebebe"></path>
                                                                    </svg>
                                                                </a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </nav>
                                                <div className="scrollup"
                                                    // style="display: none;"
                                                />
                                                <div className="scrolldown"
                                                    // style="display: block;"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </header>
};

export default LeftMenu;
