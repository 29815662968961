import React, {useState} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import jsPDF from 'jspdf';
import {style} from "./style";

const Assignement: React.FC = () => {

    const padRef = React.useRef<any>(null);
    const reportTemplateRef = React.useRef<any>(null);

    const [trimmedDataURL, setTrimmedDataURL] = useState<any>()

    return <div>
        <div ref={reportTemplateRef}>
            <div style={style.page}>
                <p className='header'>
                    IL/LA SOTTOSCRITTO/A <input style={Object.assign({}, style.input, {width: '175px'})}/>
                    NATO/A A <input style={Object.assign({}, style.input, {width: '175px'})}/>
                </p>
                <p className='header'>
                    IL <input style={Object.assign({}, style.input, {width: '65px'})}/>
                    RESIDENTE A <input style={Object.assign({}, style.input, {width: '160px'})}/>
                    IN VIA<input style={Object.assign({}, style.input, {width: '180px'})}/>
                </p>
                <p className='header'>
                    N°<input style={Object.assign({}, style.input, {width: '40px'})}/>
                    CAP <input style={Object.assign({}, style.input, {width: '75px'})}/>
                    PROVINCIA <input style={Object.assign({}, style.input, {width: '40px'})}/>
                    CODICE FISCALE <input style={Object.assign({}, style.input, {width: '174px'})}/>
                </p>

                <p><br/></p>

                <h1 style={{textAlign: 'center'}}>DICHIARA</h1>

                <p><br/></p>

                <p style={{textAlign: 'justify'}}>
                    1. Di voler affidare al Dott. Andrea Lia, l'incarico professionale per la valutazione dei propri
                    bisogni nutritivi ed energetici, ai sensi dell'art. 3 della legge n. 396/1967, e coerentemente per
                    la elaborazione di una dieta ottimale personalizzata. Il presente incarico decorre dalla
                    sottoscrizione del presente documento e si intende conferito fino alla conclusione della prestazione
                    professionale richiesta;
                    <br/>
                </p>

                <p style={{textAlign: 'justify'}}>
                    2. Di essere stato dettagliatamente informato e di condividere la validità e la convenienza di
                    sottoporsi quanto prima ad un accertamento medico per una constatazione del proprio stato
                    fisico-patologico. A tal proposito, si impegna a darne tempestiva comunicazione al Dott. Andrea Lia
                    al fine che egli possa determinare la dieta ottimale per le proprie personali e reali condizioni di
                    salute;
                    <br/>
                </p>

                <p style={{textAlign: 'justify'}}>
                    3. Di aver ben compreso quali sono i vantaggi, limiti e complicanze dei suggerimenti e del piano
                    alimentare che mi verrà proposto dalla Dott. Andrea Lia ed in particolare:
                    <br/>
                </p>

                <p style={{textAlign: 'justify'}}>
                    a) la percentuale dei risultati positivi del regime alimentare dipenderà dalle singole risposte
                    dell’organismo. Il/la sottoscritto/a è stato/a informato/a sui comportamenti da tenere, tempi
                    tecnici ed i consigli da seguire nel periodo del regime alimentare consigliato, consapevole che in
                    caso contrario il risultato finale potrebbe essere compromesso e data la natura del trattamento cui
                    decide di sottoporsi, non è possibile garantire né stabilire a priori in modo preciso l’esatto
                    risultato finale;<br/>
                    b) di aver riferito correttamente la propria anamnesi ed in particolare eventuali terapie
                    farmacologiche effettuate in passato o tutt’ora in corso, attuali o precedenti patologie e/o
                    interventi chirurgici, allergie, abitudini di vita;<br/>
                    c) di aver avuto l’opportunità di porre domande sulla sua condizione e sul regime alimentare che mi
                    verrà consigliato;<br/>
                    d) di aver letto e compreso quanto sopra riportato.

                </p>

                <p style={{textAlign: 'justify'}}>
                    4. Di non aver omesso di dichiarare alcunché rispetto al proprio attuale e pregresso stato
                    fisico-patologico e di impegnarsi a comunicare al Dott. Andrea Lia gli esiti degli accertamenti
                    sanitari a cui si sottoporrà. È mia esclusiva responsabilità informare prontamente il Dott. Andrea
                    Lia qualora sopraggiungessero variazioni del mio stato psico-patologico che abbiano inerenza
                    all'incarico conferito, esonerandola da ogni e qualsiasi responsabilità in ipotesi di mancata
                    comunicazione o di omesso accertamento. Pertanto, è mio obbligo far pervenire tempestivamente al
                    Dott. Andrea Lia tutta la documentazione e le certificazioni mediche in mio possesso o delle quali
                    verrò in possesso afferenti allo stato psico-patologico.
                </p>

                <p style={{textAlign: 'justify'}}>
                    5. Di essere stato/a informato/a e di accettare che per lo svolgimento delle prestazioni oggetto del
                    presente incarico il Dott. Andrea Lia chiede i seguenti compensi:
                </p>
                <ul style={{textAlign: 'justify'}}>
                    <li>
                        <p>70€ per la prima consulenza (completa di anamnesi, valutazione misure antropometriche,
                            valutazione composizione corporea, valutazione stile di vita ed esigenze, piano
                            alimentare);</p>
                    </li>
                    <br/><br/>
                    <li>
                        <p>30€ per eventuali consulenze successive.</p>
                    </li>
                </ul>

                <p style={{textAlign: 'justify'}}>
                    Il compenso pattuito si intende comprensivo del contributo integrativo previdenziale e della marca
                    da bollo (ove necessaria).<br/>
                    Per eventuali prestazioni specifiche diverse da quelle indicate nella presente lettera di incarico i
                    corrispondenti compensi saranno determinati sulla base di ulteriore accordo fra le parti.
                </p>

                <p style={{textAlign: 'justify'}}>
                    6. Ai sensi degli artt13 e 14 GDPR – Regolamento UE 2016/679, acconsente al trattamento dei propri
                    dati personali per l'esecuzione dell'incarico affidato alla Dott. Andrea Lia.<br/>
                    In particolare, attesta di essere stato informato/a circa:<br/>
                    a) le finalità cui sono destinati i dati;<br/>
                    b) la modalità con cui i dati vengono trattati;<br/>
                    c) la base giuridica dei dati;<br/>
                    d) le conseguenze di un eventuale rifiuto al conferimento dei dati;<br/>
                    e) il periodo di conservazione e la cancellazione dei dati;<br/>
                    f) la comunicazione dei dati;<br/>
                    g) la diffusione dei dati e il trasferimento all’estero;<br/>
                    h) i diritti dell’interessato;<br/>
                    i) il titolare del trattamento.<br/>

                </p>

                <p style={{textAlign: 'justify'}}>
                    7. Prende atto che il Dott. Andrea Lia è attualmente assicurato per la responsabilità contro i
                    rischi professionali, con apposita Polizza n. 430174767 emessa il 11/05/2023 contratta con la
                    Compagnia di assicurazioni Generali Italia Spa, con limite di indennizzo pari ad euro 500,000
                    massimale unico (in lettere: cinquecentomila).

                </p>

                <p style={{marginTop: '10pt'}}>FIRMA</p>

                <div className='sig-container'>
                    {trimmedDataURL
                        ? <img
                            alt='signature'
                            src={trimmedDataURL}/>
                        : <>
                            <div>
                                <SignatureCanvas
                                    ref={padRef}
                                    canvasProps={{width: 400, height: 100, className: 'sig-canvas'}}
                                />
                            </div>
                            <div>
                                <button type='button' onClick={() => padRef.current.clear()}>
                                    Svuota
                                </button>
                                <button
                                    type='button'
                                    onClick={() => setTrimmedDataURL(padRef.current.getTrimmedCanvas().toDataURL('image/png'))}
                                >
                                    Salva
                                </button>
                            </div>
                        </>}
                </div>
            </div>
        </div>

        {
            trimmedDataURL &&
            <button className="button" onClick={() => {
                const doc = new jsPDF({
                    format: 'a4',
                    unit: 'pt',
                });

                // Adding the fonts.
                //doc.setFont('Inter-Regular', 'normal');

                doc.html(reportTemplateRef.current, {
                    async callback(doc) {
                        await doc.save('Lettera d\'incarico');
                    },
                });
            }}>
                Generate PDF
            </button>
        }

    </div>

};

export default Assignement;
