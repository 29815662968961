export const style = {
    page: {
        fontSize: '8pt',
        margin: '20pt',
        maxWidth: '400pt',
        'page-break-after': 'always'
    },

    signContainer: {
        display: 'block',
        marginTop: '5rem',
        maxWidth: '600px',
        textAlign: 'center',
    },

    sigCanvas: {
        border: '1px solid black'
    },

    h1: {
        fontSize: '14pt'
    },

    h2: {
        fontSize: '13pt'
    },

    pageHeader: {
        marginBottom: '5pt',
    },

    input: {
        border: 'none',
        borderBottom: '1px solid black',
        padding: '1px 5px',
        textTransform: "uppercase" as "uppercase" | "lowercase"
    }
}