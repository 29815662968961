import React from 'react';
import '../../App.css';

const Contact: React.FC = () => {
    return <section className="s s-basic cf sc-ml wnd-background-pattern wnd-w-wider wnd-s-normal wnd-h-auto">
        <div className="s-w cf">
            <div className="s-o s-fs cf">
                <div className="s-bg cf">
                    <div className="s-bg-l wnd-background-pattern  bgpos-center-center bgatt-scroll"
                         style={{backgroundImage: "url('https://d1di2lzuh97fh2.cloudfront.net/files/1k/1kv/1kvudr.jpg?ph=ce5d960f58')"}}
                    />
                </div>
                <div className="s-c s-fs cf">
                    <div className="ez cf">
                        <div className="ez-c">
                            <div className="b b-text cf">
                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><h1 className="wnd-align-center"><strong>Contatti</strong></h1>
                                </div>
                            </div>
                            <div className="cw cf">
                                <div className="cw-c cf">
                                    <div className="c cf" style={{width: "50%"}}>
                                        <div className="c-c cf">
                                            <div className="b b-map b-s" style={{marginLeft: 0, marginRight: 0}}>
                                                <div className="b-c" id="wnd_MapBlock_984198561_container" style={{position: "relative", paddingBottom: "62.06%"}}>
                                                    <iframe src="https://web-2022.webnode.it/widgets/googlemaps/?z=15&amp;a=Via+Raffaele+sanzio%2C+4+lecce"
                                                            style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} loading="lazy"></iframe>
                                                </div>
                                            </div>
                                            <div className="b b-map b-s" style={{marginLeft: 0, marginRight: 0}}>
                                                <div className="b-c" id="wnd_MapBlock_257858679_container" style={{position: "relative", paddingBottom: "62.06%"}}>
                                                    <iframe src="https://web-2022.webnode.it/widgets/googlemaps/?z=15&amp;a=Via+Garibaldi%2C+7%2C+Pat%C3%B9+73053"
                                                            style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} loading="lazy"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c cf" style={{width: "50%"}}>
                                        <div className="c-c cf">
                                            <div className="b b-text cf">
                                                <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><p>Per un appuntamento o per qualsiasi esigenza:</p>
                                                </div>
                                            </div>
                                            <div className="mt mt-contact img-s-s b-s b-s-50 cf default">
                                                <div className="mt-container">
                                                    <div className="mt-item cf">
                                                        <div className="b-img b-img-default b b-s cf wnd-orientation-square wnd-type-icon" id="wnd_ImageBlock_507577189">
                                                            <div className="b-img-w">
                                                                <div className="b-img-c">

                                                                    <embed id="wnd_ImageBlock_507577189_img"
                                                                           data-src="https://d1di2lzuh97fh2.cloudfront.net/files/36/36l/36lve3.svg?ph=ce5d960f58"
                                                                           type="image/svg+xml"
                                                                           src="https://d1di2lzuh97fh2.cloudfront.net/files/36/36l/36lve3.svg?ph=ce5d960f58&amp;border=f57474&amp;outline=ef2727&amp;color=f25151"
                                                                    />
                                                                    <script>checkAndChangeSvgColor('wnd_ImageBlock_507577189_img');</script>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="b b-text cf">
                                                            <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><h3><strong>351.3037037</strong></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-item cf">
                                                        <div className="b-img b-img-default b b-s cf wnd-orientation-landscape wnd-type-icon"
                                                             id="wnd_ImageBlock_605385650">
                                                            <div className="b-img-w">
                                                                <div className="b-img-c">

                                                                    <embed id="wnd_ImageBlock_605385650_img"
                                                                           data-src="https://d1di2lzuh97fh2.cloudfront.net/files/3z/3z2/3z2m9w.svg?ph=ce5d960f58"
                                                                           type="image/svg+xml"
                                                                           src="https://d1di2lzuh97fh2.cloudfront.net/files/3z/3z2/3z2m9w.svg?ph=ce5d960f58&amp;border=f57474&amp;outline=ef2727&amp;color=f25151"
                                                                    />
                                                                    <script>checkAndChangeSvgColor('wnd_ImageBlock_605385650_img');</script>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="b b-text cf">
                                                            <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><h3><strong>andrea.lia.lecce@gmail.com</strong></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-item cf">
                                                        <div className="b-img b-img-default b b-s cf wnd-orientation-square wnd-type-icon" id="wnd_ImageBlock_812595004">
                                                            <div className="b-img-w">
                                                                <div className="b-img-c">

                                                                    <embed id="wnd_ImageBlock_812595004_img"
                                                                           data-src="https://d1di2lzuh97fh2.cloudfront.net/files/0j/0jk/0jk3ih.svg?ph=ce5d960f58"
                                                                           type="image/svg+xml"
                                                                           src="https://d1di2lzuh97fh2.cloudfront.net/files/0j/0jk/0jk3ih.svg?ph=ce5d960f58&amp;border=f57474&amp;outline=ef2727&amp;color=f25151"/>
                                                                    <script>checkAndChangeSvgColor('wnd_ImageBlock_812595004_img');</script>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="b b-text cf">
                                                            <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><h3><strong><a href="https://www.instagram.com/andrealiabio/"
                                                                                                                                     rel="nofollow"
                                                                                                                                     target="_blank">Instagram</a>&nbsp;-
                                                                andrealiabio</strong></h3></div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-item cf">
                                                        <div className="b-img b-img-default b b-s cf wnd-orientation-portrait wnd-type-icon" id="wnd_ImageBlock_111279440">
                                                            <div className="b-img-w">
                                                                <div className="b-img-c">

                                                                    <embed id="wnd_ImageBlock_111279440_img"
                                                                           data-src="https://d1di2lzuh97fh2.cloudfront.net/files/38/38e/38ej7n.svg?ph=ce5d960f58"
                                                                           type="image/svg+xml"
                                                                           src="https://d1di2lzuh97fh2.cloudfront.net/files/38/38e/38ej7n.svg?ph=ce5d960f58&amp;border=f57474&amp;outline=ef2727&amp;color=f25151"/>
                                                                        <script>checkAndChangeSvgColor('wnd_ImageBlock_111279440_img');</script>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="b b-text cf">
                                                            <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><h3><a href="https://www.facebook.com/andrealiabio"
                                                                                                                             rel="nofollow"
                                                                                                                             target="_blank"><strong>Facebook</strong></a> - Dott.
                                                                Andrea Lia, Nutrizionista</h3><p></p></div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-item cf">
                                                        <div className="b-img b-img-default b b-s cf wnd-orientation-portrait wnd-type-icon" id="wnd_ImageBlock_579397276">
                                                            <div className="b-img-w">
                                                                <div className="b-img-c">

                                                                    <embed id="wnd_ImageBlock_579397276_img"
                                                                           data-src="https://d1di2lzuh97fh2.cloudfront.net/files/0p/0p7/0p7qql.svg?ph=ce5d960f58"
                                                                           type="image/svg+xml"
                                                                           src="https://d1di2lzuh97fh2.cloudfront.net/files/0p/0p7/0p7qql.svg?ph=ce5d960f58&amp;border=f57474&amp;outline=ef2727&amp;color=f25151"
                                                                    />
                                                                    <script>checkAndChangeSvgColor('wnd_ImageBlock_579397276_img');</script>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="b b-text cf">
                                                            <div className="b-c b-text-c b-s b-s-t60 b-s-b60 b-cs cf"><h3><strong>Dott. Andrea Lia</strong></h3>
                                                                <p>📍Lecce, via Raffaello Sanzio n.4</p>
                                                                <p>📍Patù (LE), via G.Garibaldi n.7</p>
                                                                <p>📍 Consulenza online&nbsp;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

export default Contact;
